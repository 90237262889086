<template>
  <!-- Allows creation of a property with minimal information -->
  <!-- We use overflow-visible to prevent the cities dropdown from being cut off -->
  <BaseModal
    ref="modal"
    title="Nieuw pand"
    max-width="!tw-overflow-visible tw-max-w-2xl"
  >
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="propertyCreateForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="md:tw-grid tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          type="autocomplete"
          auto-complete-type="street"
          name="street"
          label="Straat"
          placeholder="Zoek een straat"
          outer-class="tw-m-0"
          @callback="streetSelected"
        />
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
          <FormulateInput
            type="text"
            name="number"
            label="Nummer"
            placeholder="Nummer"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="text"
            name="box"
            label="Bus"
            placeholder="Bus"
            outer-class="tw-m-0"
          />
        </div>
        <FormulateInput
          type="autocomplete"
          auto-complete-type="city"
          name="city"
          label="Plaats"
          placeholder="Zoek op postcode of plaatsnaam"
          validation="required"
          outer-class="tw-m-0 tw-col-span-2"
        />
        <FormulateInput
          v-if="!isRentalManagement && !isSyndic"
          type="number"
          name="price"
          label="Geschatte prijs"
          placeholder="Geschatte prijs"
          validation="bail|required|number|min:0"
          step="any"
          min="0"
          lang="nl"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="transaction_type"
          label="Transactietype"
          :options="PROPERTY_TRANSACTION_TYPES"
          validation="required"
          outer-class="tw-m-0"
        />
      </div>

      <FormulateErrors class="tw-text-right" />

      <FormulateInput
        type="submit"
        title="Aanmaken"
        :disabled="isLoading"
        :outer-class="['tw-float-right']"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
          ]"
        />
        Aanmaken
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { createProperty } from '@/services/properties'
import { PROPERTY_TRANSACTION_TYPES } from '@/forms/selectOptions'

export default {
  name: 'PropertyCreateModalCompact',
  constants: {
    PROPERTY_TRANSACTION_TYPES
  },
  data () {
    return {
      values: {}
    }
  },
  computed: {
    isRentalManagement () {
      return parseInt(this.values?.transaction_type) === 5
    },
    isSyndic () {
      return parseInt(this.values?.transaction_type) === 6
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    streetSelected (street) {
      if (street?.city) this.values.city = street.city
      if (street?.number) this.values.number = street.number
    },
    async submit (data) {
      try {
        const payload = { ...data }
        payload.city = payload.city?.id
        payload.street = payload.street?.text
        const response = await createProperty(payload)
        this.$emit('success', response.data)
        this.hide()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'propertyCreateForm')
      }
    }
  }
}
</script>
